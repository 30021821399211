import { Flex, Icon, Text, useColorMode, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { EVENTO_VIDEO_PAUSE, EVENTO_VIDEO_PLAY } from "../../../../shared/utils/types/ControllerTypes";
import { uploadProgress } from "../../../../shared/middlewares/progress.middleware";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { useAuthContext } from "../../../../shared/context/user.context";
import { useProgressContext } from "../../../../shared/context/progress.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";
import { HiOutlinePaperClip } from "react-icons/hi";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { OpenPlayer } from "../../../../shared/components/OpenPlayer/OpenPlayer";


interface Props {
    leccion: LeccionInt | null;
    onLeccionStarted?: (e?: any) => any;
    tipo?: PreviewTipoEnum;
}

export const Video = ({
    leccion,
    onLeccionStarted = () => { },
    tipo = PreviewTipoEnum.TEORIA
}: Props) => {
    const { colorMode } = useColorMode();
    const { user } = useAuthContext();
    const toast = useToast();
    const playerRef = useRef<any>();
    const [playedSeconds, setPlayedSeconds] = useState<number>(0);
    const { cursoProgress, refreshProgress } = useProgressContext();
    const [segundosLeccion, setSegundosLeccion] = useState<number>(0)

    useEffect(() => {
        if (leccion?.id && cursoProgress?.data)
            setSegundosLeccion(cursoProgress?.data[leccion?.id]?.segundos)

    }, [leccion?.id, cursoProgress])

    async function updateProgresoVideo() {
        if (!leccion || !leccion?.id || !cursoProgress) return

        if (
            user?.role?.nombre === UserRolEnum.TUTOR ||
            user?.role?.nombre === UserRolEnum.ADMIN ||
            tipo === PreviewTipoEnum.ENTREGA
        ) return

        if (
            cursoProgress?.data[leccion?.id] &&
            leccion?.modulo?.cursoId &&
            cursoProgress?.matriculaId
        ) {
            if (cursoProgress?.data[leccion?.id]?.estado !== "finalizacion")
                await uploadProgress({
                    matriculaId: cursoProgress?.matriculaId,
                    cursoId: leccion?.modulo?.cursoId,
                    moduloId: leccion?.moduloId,
                    leccion: {
                        id: leccion?.id,
                        estado: "acceso",
                        segundos: Math.round(playedSeconds),
                    }
                }).then(() => refreshProgress())
                    .catch((error) => console.error(error))
        }
    }

    const handleOnStart = async () => {
        if (tipo === PreviewTipoEnum.ENTREGA) return

        if (leccion) {
            onLeccionStarted(leccion);
        } else {
            toastNotify(
                toast,
                StatusEnumTypes.ERROR,
                'La lección es indefinida. Actualice la página y contacte con soporte si el error persiste..'
            );
        }
    };

    function onPlay() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PLAY);

        window.dispatchEvent(onPlayEvent);
    }

    function onPause() {
        const onPlayEvent = new Event(EVENTO_VIDEO_PAUSE);

        window.dispatchEvent(onPlayEvent);
    }

    return (
        <Flex
            p={tipo === PreviewTipoEnum.TEORIA ? "40px" : "0"}
            border={tipo === PreviewTipoEnum.TEORIA ? "1px solid" : "none"}
            w="100%"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="40px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                direction="column"
                display={tipo === PreviewTipoEnum.TEORIA ? "flex" : "none"}
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlinePaperClip}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Lección
                    </Text>
                </Flex>
            </Flex>

            <OpenPlayer
                player={playerRef}
                leccion={leccion}
                maxSpeed={2}
                minSpeed={0.25}
                onPlay={onPlay}
                onPause={onPause}
                onStart={handleOnStart}
                onUpdate={updateProgresoVideo}
                setPlayedSeconds={setPlayedSeconds}
                playedSeconds={playedSeconds}
                src={leccion?.contenido || undefined}
                segundosLeccion={segundosLeccion}
            />
        </Flex>
    )
};