import { Button, DarkMode, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorMode } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { MatriculasInt } from '../../../interfaces/MatriculasInt';
import { useAuthContext } from '../../context/user.context';
import { format } from '../../utils/functions/formatDate';

interface Props {
    onClose: () => void;
    isOpen: boolean;
    handleDiploma: (matriculaId: string | undefined) => void;
    matricula: MatriculasInt;
    loading: boolean;
}

export default function InfoDiplomaModal({ isOpen, onClose, handleDiploma, matricula, loading }: Props) {
    const { colorMode } = useColorMode();
    const { user } = useAuthContext();
    const navigate = useNavigate();

    return (
        <Modal
            size="2xl"
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                padding={"30px"}
                rounded="10px"
                bg={colorMode === "light" ? "#FFFFFF" : "bg_dark_light"}
                shadow={"0px 4px 10px rgba(0, 0, 0, 0.1)"}
            >
                <ModalCloseButton />


                <ModalHeader
                    display={"flex"}
                    flexDirection={"column"}
                    gap="10px"
                >
                    <Text color="font" fontSize="24px" fontWeight="600" lineHeight="normal">
                        Datos del diploma
                    </Text>

                    <Text
                        color={colorMode === "light" ? "secondary" : "purewhite"}
                        fontSize="18px"
                        fontWeight="700"
                    >
                        ATENCIÓN: <Text as="span" color="font" fontSize="16px" fontWeight="400"> Revisa tus datos antes de generar el diploma definitivo.</Text>
                    </Text>
                </ModalHeader>

                <ModalBody
                    display="flex"
                >
                    <Flex
                        display={matricula?.grupo?.fundae ? "flex" : "none"}
                        flex="1"
                        direction="column"
                        gap="15px"
                    >
                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Curso:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.curso?.nombre}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Modalidad:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700" textTransform="capitalize">{matricula?.grupo?.modalidad ? matricula?.grupo?.modalidad : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Fecha de inicio:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.fechaInicio ? format({ date: matricula?.grupo?.fechaInicio, customFormat: "dd/MM/yyyy" }) : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Fecha de fin:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.fechaFin ? format({ date: matricula?.grupo?.fechaFin, customFormat: "dd/MM/yyyy" }) : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Código AF:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.accionFormativa ? matricula?.grupo?.accionFormativa : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Código Grupo:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.codigoGrupo ? matricula?.grupo?.codigoGrupo : "---"}</Text>
                        </Flex>
                    </Flex>

                    <Flex
                        flex="1"
                        direction="column"
                        gap="15px"
                    >
                        <Flex
                            display={!matricula?.grupo?.fundae ? "flex" : "none"}
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Curso:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.curso?.nombre}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Nombre:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{user?.nombre} {user?.apellidos}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">NIF:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{user?.dni}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Empresa:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{user?.empresa?.nombre}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">CIF:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{user?.empresa?.cif}</Text>
                        </Flex>

                        <Flex
                            display={matricula?.grupo?.fundae ? "flex" : "none"}
                            gap="10px"
                        >
                            <Text color="font" fontSize="16px" fontWeight="400">Fecha de expedición:</Text>
                            <Text color="font" fontSize="16px" fontWeight="700">{matricula?.grupo?.fechaFin ? format({ date: matricula?.grupo?.fechaFin, customFormat: "dd/MM/yyyy" }) : "---"}</Text>
                        </Flex>
                    </Flex>
                </ModalBody>

                <ModalFooter gap="20px">
                    <Button
                        color={colorMode === "light" ? "main" : "purewhite"}
                        bg="transparent"
                        border={`2px solid `}
                        borderColor={colorMode === "light" ? "main" : "purewhite"}
                        p="10px"
                        h="fit-content"
                        w="fit-content"
                        rounded="10px"
                        fontSize="16px"
                        fontWeight="600"
                        onClick={() => {
                            onClose();
                            navigate(`/perfil`)
                        }}
                        _hover={{ bgColor: "variant", borderColor: "variant", color: "purewhite" }}
                    >
                        Solicitar cambios
                    </Button>

                    <Button
                        isLoading={loading}
                        bg="main"
                        color="purewhite"
                        p="10px"
                        h="fit-content"
                        w="fit-content"
                        rounded="10px"
                        fontSize="16px"
                        fontWeight="600"
                        border={`2px solid `}
                        borderColor={"transparent"}
                        _hover={{ color: "purewhite", bgColor: "variant" }}
                        onClick={() => handleDiploma(matricula.id)}
                    >
                        Descargar
                    </Button>
                </ModalFooter>
            </ModalContent>

        </Modal>
    )
}
