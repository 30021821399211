import { Flex, Icon, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorMode, useToast } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useData } from "../../../../shared/hooks/useData"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useAuthContext } from "../../../../shared/context/user.context"
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum"
import { MatriculasInt } from "../../../../interfaces/MatriculasInt"
import { useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors"
import { HeaderEntregables } from "../../components/EntregableHeader"
import { EntregablesInt } from "../../../../interfaces/Entregables"
import { EntregableEstadoEnum } from "../../../../shared/utils/types/EntregableEstadoEnum"
import { Table } from "../../../../shared/components/Table/Table"
import { EntregableColumns } from "./Columns/EntregableColumns"
import { HiOutlineClock } from "react-icons/hi"
import { BiCheckCircle, BiXCircle } from "react-icons/bi"
import { TypeCardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"
import { EstadoTypeEnum } from "../../../../shared/utils/types/EstadoTypeEnum"

export const Entregables = () => {
    const { user, logout } = useAuthContext()
    const toast = useToast()
    const navigate = useNavigate()
    const { colorMode } = useColorMode()
    const [search, setSearch] = useSearchParams()

    const { data, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })
    const matriculas = data?.data?.filter((matricula: MatriculasInt) => matricula?.meta?.tutorizado);

    const [selectedMatricula, setSelectedMatricula] = useState<MatriculasInt>();

    const { data: entregables, loading } = useData({
        endpoint: EndpointTypes.ENTREGABLES,
        query: {
            sortBy: "fechaEntrega",
            order: "desc",
            matricula: selectedMatricula?.id as string,
            limit: 100
        },
        ignoreRequest: !selectedMatricula
    })

    useEffect(() => {
        if (!selectedMatricula && matriculas) setSelectedMatricula(matriculas[0])
    }, [matriculas])

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                errors: errorsList,
                status: status,
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    const handleEntregablesFilter = (
        entregables: EntregablesInt[] | undefined,
        filterType: "incorrectos" | "pendientes" | "correctos" | "entregados"
    ): EntregablesInt[] => {
        let entregablesFilter: EntregablesInt[] = [];

        if (!entregables || entregables?.length === 0) return entregablesFilter

        if (filterType === "pendientes") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.PENDIENTE_ENTREGA || e.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION)
        if (filterType === "incorrectos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.ERROR)
        if (filterType === "correctos") entregablesFilter = entregables?.filter((e: EntregablesInt) => e.estado === EntregableEstadoEnum.CORRECTO)

        return entregablesFilter
    }

    const handleSelectMatricula = (matricula: MatriculasInt) => {
        setSelectedMatricula(matricula);

        search.set("matricula", `${matricula?.id}`);
        setSearch(search);
    };

    const redirectTo = (data: EntregablesInt): string | null => {
        if (
            !selectedMatricula ||
            selectedMatricula?.estado === EstadoTypeEnum.INACTIVO ||
            !selectedMatricula?.grupo?.slug
        ) return null

        return `/aprendizaje/${selectedMatricula?.grupo?.curso?.slug}/lecciones/${data?.leccion?.slug}?grupo=${selectedMatricula?.grupo?.slug}`
    }

    return (
        <Topbar
            title={"Entregables"}
            searchBar
        >
            <Flex
                px="30px"
                py="20px"
                w="100%"
                direction="column"
                gap="30px"
            >
                <HeaderEntregables
                    onSelectMatricula={handleSelectMatricula}
                    matriculas={matriculas}
                    selectedMatricula={selectedMatricula}
                    totalEntregables={entregables?.meta?.total || 0}
                    entregablesAprobados={handleEntregablesFilter(entregables?.data, "correctos")?.length || 0}
                />

                <Tabs
                    w="100%"
                    p="0"
                >
                    <TabList
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                        lineHeight="22px"
                        gap="20px"
                        mb="30px"
                        borderColor={colorMode === "dark" ? "border_variant" : undefined}
                    >

                        <Tab
                            gap="10px"
                            px="40px"
                            alignItems="center"
                            color={colorMode === "dark" ? "purewhite" : "dark_grey"}
                            _selected={{
                                borderBottomWidth: "2px",
                                borderBottomColor: colorMode === "dark" ? "main" : "secondary",
                                color: colorMode === "dark" ? "main" : "secondary"
                            }}
                        >
                            <Icon as={HiOutlineClock} boxSize="20px" />

                            Pendientes
                        </Tab>

                        <Tab gap="10px"
                            px="40px"
                            alignItems="center"
                            color={colorMode === "dark" ? "bo" : "dark_grey"}
                            _selected={{
                                borderBottomWidth: "2px",
                                borderBottomColor: colorMode === "dark" ? "main" : "secondary",
                                color: colorMode === "dark" ? "main" : "secondary"
                            }}
                        >
                            <Icon as={BiCheckCircle} boxSize="20px" />

                            Correctos
                        </Tab>

                        <Tab
                            gap="10px"
                            px="40px"
                            alignItems="center"
                            color={colorMode === "dark" ? "purewhite" : "dark_grey"}
                            _selected={{
                                borderBottomWidth: "2px",
                                borderBottomColor: colorMode === "dark" ? "main" : "secondary",
                                color: colorMode === "dark" ? "main" : "secondary"
                            }}
                        >
                            <Icon as={BiXCircle} boxSize="20px" />

                            Incorrectos

                        </Tab>
                    </TabList>

                    <TabPanels
                        p="0"
                    >
                        <TabPanel
                            p="0"
                        >
                            <Table
                                columns={EntregableColumns({ redirectTo })}
                                data={handleEntregablesFilter(entregables?.data, "pendientes")}
                                loading={loading}
                                total={handleEntregablesFilter(entregables?.data, "pendientes").length || 0}
                                showFooter={false}
                                typeSinInformacion={TypeCardSinInformacion.ENTREGABLES}
                            />
                        </TabPanel>

                        <TabPanel
                            p="0"
                        >
                            <Table
                                columns={EntregableColumns({ redirectTo })}
                                data={handleEntregablesFilter(entregables?.data, "correctos")}
                                loading={loading}
                                total={handleEntregablesFilter(entregables?.data, "correctos").length || 0}
                                showFooter={false}
                                typeSinInformacion={TypeCardSinInformacion.ENTREGABLES}
                            />
                        </TabPanel>

                        <TabPanel
                            p="0"
                        >
                            <Table
                                columns={EntregableColumns({ redirectTo })}
                                data={handleEntregablesFilter(entregables?.data, "incorrectos")}
                                loading={loading}
                                total={handleEntregablesFilter(entregables?.data, "incorrectos").length || 0}
                                showFooter={false}
                                typeSinInformacion={TypeCardSinInformacion.ENTREGABLES}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </Topbar>
    )
}