const tenantInstance = async (): Promise<{ tenant: string; client: string; }> => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const urlBase = urlObj.origin;
    const dominio = urlObj.hostname;
    const tenant = dominio.split('.')[0];

    if (urlBase === import.meta.env.VITE_DOMINIO_IMAGINA || "")
        return {
            tenant: "imagina",
            client: "campus"
        };
    else
        return {
            tenant: tenant,
            client: "campus"
        };
}

export default tenantInstance;