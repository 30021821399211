import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure, useColorMode, useToast } from "@chakra-ui/react"
import { ExamenInt } from "../../../../interfaces/ExamenInt";
import { useCountdown } from "../../../../shared/hooks/useCountdown";
import { useEffect, useState } from "react";
import { HeaderQuiz } from "./Items/HeaderQuiz";
import { StartQuiz } from "./Items/StartQuiz";
import { ProgressQuiz } from "./Items/ProgressQuiz";
import { EndQuiz } from "./Items/EndQuiz";
import { endExamen } from "../../../../shared/middlewares/examenes.middleware";
import { AxiosResponse } from "axios";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";

export enum MODE {
    START = "start",
    PROGRESS = "progress",
    END = "end"
}


interface Props {
    examen: ExamenInt;
    matriculaId: string | undefined;
    onClose: () => void;
    isOpen: boolean;
}

export const Quiz = ({
    examen,
    matriculaId,
    onClose,
    isOpen
}: Props) => {
    const toast = useToast()
    const { colorMode } = useColorMode()
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();
    const [estado, setEstado] = useState<MODE>(MODE.START)
    const [aprobado, setAprobado] = useState<boolean>()
    const [loadingResultado, setLoadingResultado] = useState<boolean>(false)
    const [preguntaActualIndex, setPreguntaActualIndex] = useState<number>(1);
    const [respuestas, setRespuestas] = useState<any[]>([]);
    const [respuestaActual, setRespuestaActual] = useState<any>();
    const [
        progress,
        minutes,
        seconds,
        secs,
        decrementSecs,
        restartCountdown
    ] = useCountdown((examen?.duracion || 0) / 60);

    useEffect(() => {
        if (estado !== MODE.START) setEstado(MODE.START)
        setAprobado(undefined)
    }, [])

    const onStartExamen = () => {
        if (!examen) return;
        restartCountdown()
        setEstado(MODE.PROGRESS)
    }

    const onEndExamen = (respuestas: string[]) => {
        if (
            !examen?.id ||
            !respuestas ||
            respuestas?.length === 0 ||
            !matriculaId
        ) return;

        setLoadingResultado(true)

        endExamen({
            id: examen?.id,
            respuestas,
            matriculaId
        })
            .then((response: AxiosResponse) => {
                setAprobado(response?.data?.data?.aprobado)
                setEstado(MODE.END)
            })
            .catch(() => {
                toastNotify(toast, StatusEnumTypes.ERROR, "Hubo un error al finalizar el examen")

                setAprobado(undefined)
                setEstado(MODE.START)
            })
            .finally(() => {
                setPreguntaActualIndex(1)
                setLoadingResultado(false)
            })
    }

    const outExamen = () => {
        onClose()
        setEstado(MODE.START)
        setAprobado(undefined)
        setRespuestaActual(undefined);
        setRespuestas([]);
        setPreguntaActualIndex(1);
    }

    return (
        <Modal
            onClose={() => null}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={false}
            closeOnEsc={false}
            size="full"
        >
            <ModalOverlay />

            <ModalContent
                bg={colorMode === "dark" ? "bg_dark" : "#FFFFFF"}
            >
                <ModalBody
                    p="0"
                >
                    <Flex
                        direction="column"
                    >
                        <HeaderQuiz
                            examen={examen}
                            estado={estado}
                            preguntaActualIndex={preguntaActualIndex}
                            minutes={minutes}
                            seconds={seconds}
                            outExamen={outExamen}
                            onOpenAlert={onOpenAlert}
                        />

                        <Box
                            px="100px"
                            h="calc(100vh - 120px)"
                        >
                            {
                                estado === MODE.START &&
                                <StartQuiz
                                    onStartExamen={onStartExamen}
                                />
                            }

                            {
                                estado === MODE.PROGRESS &&
                                <ProgressQuiz
                                    examen={examen}
                                    preguntaActualIndex={preguntaActualIndex}
                                    setPreguntaActualIndex={setPreguntaActualIndex}
                                    respuestaActual={respuestaActual}
                                    setRespuestaActual={setRespuestaActual}
                                    onEndExamen={onEndExamen}
                                    isLoading={loadingResultado}
                                    decrementSecs={decrementSecs}
                                    secs={secs}
                                    respuestas={respuestas}
                                    setRespuestas={setRespuestas}
                                    onClose={onCloseAlert}
                                    isOpen={isOpenAlert}
                                    outExamen={outExamen}
                                />
                            }

                            {
                                estado === MODE.END &&
                                <EndQuiz
                                    examen={examen}
                                    aprobado={aprobado}
                                    onStartExamen={onStartExamen}
                                    isLoading={loadingResultado}
                                    outExamen={outExamen}
                                />
                            }
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}