import { Box, Flex, Icon, Text, Tooltip, useColorMode } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { FilterDateInput } from "@imaginagroup/bit-components.ui.filters.filter-date-input"
import { FilterInput } from "@imaginagroup/bit-components.ui.filters.filter-input"
import { FilterSelect } from "@imaginagroup/bit-components.ui.filters.filter-select"
import { jsDateFormat } from "../../../shared/utils/functions/formatDate";
import { BiTrashAlt } from "react-icons/bi";
import { filterSelectStyles, filterSelectStylesDark } from "../../../ui/Select/filterSelectStyles";
import { EntregableEstadoEnum } from "../../../shared/utils/types/EntregableEstadoEnum"
import "../../../ui/sass/FilterDateInput.scss"

interface Props {
    data?: any[]
    grupos: any[]
    query: {
        entregableEstado?: string;
        nombreCompleto?: string;
        grupo?: string;
        curso?: string;
        fechaEntregaSup?: string;
        fechaEntregaInf?: string;
    };
    setQuery: Dispatch<SetStateAction<{
        entregableEstado?: string;
        nombreCompleto?: string;
        grupo?: string;
        curso?: string;
        fechaEntregaSup?: string;
        fechaEntregaInf?: string;
    }>>;
}

export const Filter = ({
    query,
    setQuery,
    data,
    grupos
}: Props) => {
    const { colorMode } = useColorMode();
    const [valueEstado, setValueEstado] = useState<{ label: string; value: string; } | null>(null)
    const [valueCurso, setValueCurso] = useState<{ label: string; value: string; } | null>(null)
    const [valueGrupo, setValueGrupo] = useState<{ label: string; value: string } | null>(null)
    const [valueNombreCompleto, setValueNombreCompleto] = useState<string>("")
    const [grupoPlaceholder, setGrupoPlaceholder] = useState<string>("Grupo");

    useEffect(() => {
        const entregableEstado = estadoValue(query?.entregableEstado)
        setValueEstado(entregableEstado)

        const curso = cursoValue(query?.curso, data)
        setValueCurso(curso)

        const grupo = grupoValue(query?.grupo, data)
        setValueGrupo(grupo)
    }, [query, data])

    const estadoValue = (value: string | undefined) => {
        if (value === EntregableEstadoEnum.PENDIENTE_CORRECCION) return { value: "0", label: "Pendiente" }
        if (value === EntregableEstadoEnum.CORRECTO) return { value: "1", label: "Correcto" }
        if (value === EntregableEstadoEnum.ERROR) return { value: "2", label: "Incorrecto" }

        return null
    }

    const cursoValue = (value: string | undefined, data: any[] | undefined) => {
        const cursos = cursosOptions(data)

        const find = cursos?.find((c) => c?.value === value)

        return find ? find : null
    }

    const grupoValue = (value: string | undefined, data: any[] | undefined) => {
        const grupos = gruposOptions(data)

        const find = grupos?.find((g) => g?.value === value)

        return find ? find : null
    }

    const gruposOptions = (data: any[] | undefined) => {
        if (!data) return []

        const grupos: { nombre: string; id: string; }[] = []
        const options: { label: string; value: string; }[] = []

        data?.forEach((e) => {
            const grupoNombre = e?.matricula?.grupo?.nombre
            const grupoId = e?.matricula?.grupo?.id

            const exists = grupos.some(grupo => grupo.id === grupoId)

            if (!exists) {
                grupos.push({
                    nombre: grupoNombre,
                    id: grupoId
                })
            }
        })

        grupos?.forEach((g) => options.push({ label: g?.nombre, value: g?.id }))

        return options
    }

    const cursosOptions = (data: any[] | undefined) => {
        if (!data) return []

        const cursos: { nombre: string; id: string; }[] = []
        const options: { label: string; value: string; }[] = []

        data?.forEach((e) => {
            const cursoNombre = e?.curso?.nombre
            const cursoId = e?.cursoId

            const exists = cursos.some(curso => curso.id === cursoId)

            if (!exists) {
                cursos.push({
                    nombre: cursoNombre,
                    id: cursoId
                })
            }
        })

        cursos?.forEach((c) => options.push({ label: c?.nombre, value: c?.id }))
        
        return options
    }

    console.log("wawawiwa", gruposOptions(data))

    console.log("DATA", data)
    console.log("GRUPOS", grupos)

    return (
        <Flex
            className={colorMode === "dark" ? "calendar-dark" : "calendar-light"}
            rounded="16px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
            p="20px"
            gap="20px"
        >
            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
                w="100%"
            >
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Estado
                </Text>

                <Box
                    position="relative"
                >
                    <FilterSelect
                        value={valueEstado}
                        placeholder="Estado"
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                entregableEstado: e?.value
                            }

                            setQuery(newQuery)
                        }}
                        options={[
                            { value: "pendiente_correccion", label: "Pendiente" },
                            { value: "correcto", label: "Correcto" },
                            { value: "error", label: "Incorrecto" },
                        ]}
                        ui={{
                            filterSelectStyles: colorMode === "dark" ? filterSelectStylesDark : filterSelectStyles
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.entregableEstado ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.grupo) newQuery = { curso: query?.grupo }
                            if (query?.nombreCompleto) newQuery = { curso: query?.nombreCompleto }
                            if (query?.curso) newQuery = { curso: query?.curso }
                            if (query?.fechaEntregaSup) newQuery = { fechaEntregaSup: query?.fechaEntregaSup }
                            if (query?.fechaEntregaInf) newQuery = { fechaEntregaInf: query?.fechaEntregaInf }

                            setQuery(newQuery)
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
                w="100%"
            >
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Alumno
                </Text>

                <Box
                    position="relative"
                >

                    <FilterInput
                        value={valueNombreCompleto}
                        placeholder="Alumno"
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                nombreCompleto: e.target.value
                            }

                            setQuery(newQuery)
                            setValueNombreCompleto(e.target.value)
                        }}
                        ui={{
                            styleInput: {
                                border: colorMode === "dark" ? "1px solid #576776" : "1px solid #E6E6EA",
                                bg: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                color: colorMode === "dark" ? "purewhite" : "font"
                            }
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.nombreCompleto ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.grupo) newQuery = { curso: query?.grupo }
                            if (query?.entregableEstado) newQuery = { curso: query?.entregableEstado }
                            if (query?.curso) newQuery = { curso: query?.curso }
                            if (query?.fechaEntregaSup) newQuery = { fechaEntregaSup: query?.fechaEntregaSup }
                            if (query?.fechaEntregaInf) newQuery = { fechaEntregaInf: query?.fechaEntregaInf }

                            setQuery(newQuery)
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
                w="100%"
            >
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Grupo
                </Text>

                <Box
                    position="relative"
                >
                    <FilterSelect
                        value={valueGrupo}
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                grupo: e?.value
                            }

                            delete newQuery.curso

                            setValueGrupo(e);
                            setGrupoPlaceholder(e?.label || "Grupo");

                            setQuery(newQuery);
                        }}
                        placeholder={grupoPlaceholder}
                        options={gruposOptions(data)}
                        ui={{
                            filterSelectStyles: colorMode === "dark" ? filterSelectStylesDark : filterSelectStyles
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.grupo ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();

                            const newQuery = { ...query };
                            delete newQuery.grupo;

                            setGrupoPlaceholder("Grupo");

                            if (query?.entregableEstado) newQuery.entregableEstado = query.entregableEstado;
                            if (query?.curso) newQuery.curso = query.curso;
                            if (query?.nombreCompleto) newQuery.nombreCompleto = query.nombreCompleto;
                            if (query?.fechaEntregaSup) newQuery.fechaEntregaSup = query.fechaEntregaSup;
                            if (query?.fechaEntregaInf) newQuery.fechaEntregaInf = query.fechaEntregaInf;

                            setQuery(newQuery);
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="1"
            >
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Curso
                </Text>

                <Box
                    position="relative"
                    opacity={query?.grupo ? "0.5" : 1}
                >
                    <Tooltip
                        label="No puedes filtrar por curso si tienes un grupo seleccionado"
                        isDisabled={!query?.grupo}
                        hasArrow
                        rounded="5px"
                        p="5px"
                        textAlign="center"
                    >
                        <Box
                            display={query?.grupo ? "block" : "none"}
                            position="absolute"
                            bottom="0"
                            top="0"
                            left="0"
                            right="0"
                            cursor={"not-allowed"}
                            zIndex="9999"
                        />
                    </Tooltip>

                    <FilterSelect
                        value={valueCurso}
                        placeholder="Curso"
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                curso: e?.value
                            }

                            setQuery(newQuery)
                        }}
                        options={cursosOptions(data)}
                        ui={{
                            filterSelectStyles: colorMode === "dark" ? filterSelectStylesDark : filterSelectStyles
                        }}
                    />

                    <Icon
                        position="absolute"
                        right="35px"
                        top="12px"
                        zIndex="999"
                        as={BiTrashAlt}
                        color="font"
                        boxSize="16px"
                        cursor="pointer"
                        _active={{ transform: "scale(0.9)" }}
                        display={query?.curso ? "block" : "none"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                            const newQuery = { ...query };

                            delete newQuery.curso;

                            if (query?.entregableEstado) newQuery.entregableEstado = query.entregableEstado;
                            if (query?.nombreCompleto) newQuery.nombreCompleto = query.nombreCompleto;
                            if (query?.grupo) newQuery.grupo = query.grupo;
                            if (query?.fechaEntregaSup) newQuery.fechaEntregaSup = query.fechaEntregaSup;
                            if (query?.fechaEntregaInf) newQuery.fechaEntregaInf = query.fechaEntregaInf;

                            setQuery(newQuery);
                        }}
                    />
                </Box>
            </Flex>

            <Flex
                w="100%"
                direction="column"
                justifyContent="start"
                gap="15px"
                flex="2"
            >
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    Fecha de Entrega
                </Text>

                <Flex
                    alignItems="center"
                    w="100%"
                    gap="15px"
                >
                    <Box
                        position="relative"
                    >
                        <FilterDateInput
                            locale="es"
                            value={query?.fechaEntregaSup ? jsDateFormat({ date: query?.fechaEntregaSup, format: "yyyy-MM-dd" }) as string : ""}
                            onChange={(e) => {
                                const newQuery = {
                                    ...query,
                                    fechaEntregaSup: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                                }

                                setQuery(newQuery)
                            }}
                        />

                        <Flex
                            position="absolute"
                            right="27px"
                            top="10px"
                            zIndex="999"
                            boxSize="40px"
                            alignItems="center"
                            justifyContent="center"
                            roundedTopRight="6px"
                            roundedBottomRight="6px"
                            display={query?.fechaEntregaSup ? "block" : "none"}
                            cursor={query?.fechaEntregaSup ? "pointer" : "not-allowed"}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                let newQuery = {}

                                if (query?.entregableEstado) newQuery = { entregableEstado: query?.entregableEstado }
                                if (query?.curso) newQuery = { curso: query?.curso }
                                if (query?.nombreCompleto) newQuery = { curso: query?.nombreCompleto }
                                if (query?.grupo) newQuery = { curso: query?.grupo }
                                if (query?.fechaEntregaInf) newQuery = { fechaEntregaInf: query?.fechaEntregaInf }

                                setQuery(newQuery)
                            }}
                        >
                            <Icon
                                as={BiTrashAlt}
                                color="font"
                                boxSize="16px"
                            />
                        </Flex>
                    </Box>

                    <Text
                        color="#515561"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        -
                    </Text>

                    <Box
                        position="relative"
                    >
                        <FilterDateInput
                            locale="es"
                            value={query?.fechaEntregaInf ? jsDateFormat({ date: query?.fechaEntregaInf, format: "yyyy-MM-dd" }) as string : ""}
                            onChange={(e) => {
                                const newQuery = {
                                    ...query,
                                    fechaEntregaInf: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                                }

                                setQuery(newQuery)
                            }}
                        />

                        <Flex
                            position="absolute"
                            right="27px"
                            top="10px"
                            zIndex="999"
                            boxSize="40px"
                            alignItems="center"
                            justifyContent="center"
                            roundedTopRight="6px"
                            roundedBottomRight="6px"
                            display={query?.fechaEntregaInf ? "block" : "none"}
                            cursor={query?.fechaEntregaInf ? "pointer" : "not-allowed"}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                let newQuery = {}

                                if (query?.entregableEstado) newQuery = { entregableEstado: query?.entregableEstado }
                                if (query?.curso) newQuery = { curso: query?.curso }
                                if (query?.nombreCompleto) newQuery = { curso: query?.nombreCompleto }
                                if (query?.grupo) newQuery = { curso: query?.grupo }
                                if (query?.fechaEntregaSup) newQuery = { fechaEntregaSup: query?.fechaEntregaSup }

                                setQuery(newQuery)
                            }}
                        >
                            <Icon
                                as={BiTrashAlt}
                                color="font"
                                boxSize="16px"
                            />
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Flex >
    )
}
