import { Box, Flex, Icon, Text, useColorMode } from "@chakra-ui/react"
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { Dispatch, SetStateAction } from "react";
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { HiOutlineClipboard } from "react-icons/hi";
import { EntregablesInt } from "../../../../interfaces/Entregables";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";
import { EntregableEstadoEnum, TipoEntregaEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";
import { EntregaPendiente } from "./components/PedienteCorreccion";
import { EntregaCorrecto } from "./components/Correcto";
import { EntregaError } from "./components/Error";
import { EntregarFile } from "./components/Entrega";
import { CorreccionTutor } from "./components/CorreccionTutor";

interface Props {
    leccion: LeccionInt | undefined;
    entregable: EntregablesInt | null;
    entregables: EntregablesInt[];
    endPrev: boolean;
    realizarEntrega: (action: { file: File | null, setFile: Dispatch<SetStateAction<File | null>>, value: string, setValue: Dispatch<SetStateAction<string>> }) => void;
    componentSubtype: ComponenteLeccionEnum | undefined;
    loading: boolean;
}

export const EntregadoItem = ({
    leccion,
    entregable,
    realizarEntrega,
    endPrev,
    entregables,
    componentSubtype,
    loading
}: Props) => {
    const { colorMode } = useColorMode();

    const handleFilter = (data: EntregablesInt[], entregableId: string | undefined) => {
        if (!entregableId) return []

        return data?.filter((entregable: EntregablesInt) => entregable?.id !== entregableId)
    }

    return (
        <Flex
            p="40px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="25px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                direction="column"
                mb="6px"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlineClipboard}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Entregable
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w="100%"
                gap="20px"
                direction="column"
            >
                <Box
                    h="1px"
                    bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    w="100%"
                />

                <Flex
                    alignItems="center"
                    gap="30px"
                >
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        Estado:

                        <Text
                            py="4px"
                            px="15px"
                            rounded="8px"
                            bg={
                                entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION ? "warning_bg" :
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? "fail_bg" :
                                        entregable?.estado === EntregableEstadoEnum.CORRECTO ? "success_bg" : "#F0F0F3"
                            }
                            as="span"
                            ml="10px"
                            color={
                                entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION ? "warning" :
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? "fail" :
                                        entregable?.estado === EntregableEstadoEnum.CORRECTO ? "success" : "#515561"
                            }
                            fontSize="16px"
                            fontWeight="600"
                        >
                            {
                                entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION ? "Pendiente de Correccíon" :
                                    entregable?.estado === EntregableEstadoEnum.ERROR ? "Suspedido" :
                                        entregable?.estado === EntregableEstadoEnum.CORRECTO ? "Superado" : "---"
                            }
                        </Text>
                    </Text>

                    <Text
                        display={entregable?.estado === EntregableEstadoEnum.ERROR || entregable?.estado === EntregableEstadoEnum.CORRECTO ? "block" : "none"}
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        Nota:

                        <Text
                            as="span"
                            py="4px"
                            px="15px"
                            rounded="8px"
                            fontWeight="700"
                            fontSize="16px"
                            ml="10px"
                            bg="#F0F0F3"
                            color="#515561"
                        >
                            {entregable?.correccion?.puntuacion}
                        </Text>
                    </Text>
                </Flex>

                <Box
                    h="1px"
                    bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    w="100%"
                />
            </Flex>

            {leccion?.contenido &&
                <>
                    <Flex
                        w="100%"
                    >
                        {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                            <PDFViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                            <Video
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                            <IframeViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                            <ImageViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                            <MarkdownViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }
                    </Flex>

                    <Box
                        h="1px"
                        bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                        w="100%"
                    />
                </>
            }

            {entregable?.estado === EntregableEstadoEnum.PENDIENTE_CORRECCION &&
                <EntregaPendiente
                    entregable={entregable}
                    entregables={handleFilter(entregables, entregable?.id)}
                />
            }

            {entregable?.estado === EntregableEstadoEnum.CORRECTO &&
                <>
                    <EntregaCorrecto
                        entregable={entregable}
                        entregables={handleFilter(entregables, entregable?.id)}
                    />

                    {(entregable?.correccion?.adjunto || entregable?.correccion?.texto) &&
                        <>
                            <Box h="1px"
                                bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                w="100%"
                            />

                            <CorreccionTutor
                                correccion={entregable?.correccion}
                            />
                        </>
                    }

                    <Box h="1px"
                        bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                        w="100%"
                    />

                    {(entregable?.correccion && entregable?.correccion?.puntuacion < 100) &&
                        <EntregarFile
                            realizarEntrega={realizarEntrega}
                            endPrev={endPrev}
                            tipo={TipoEntregaEnum.REENTREGA}
                            leccion={leccion}
                            loading={loading}
                        />
                    }
                </>
            }

            {entregable?.estado === EntregableEstadoEnum.ERROR &&
                <>
                    <EntregaError
                        entregable={entregable}
                        entregables={handleFilter(entregables, entregable?.id)}
                    />

                    {(entregable?.correccion?.adjunto || entregable?.correccion?.texto) &&
                        <>
                            <Box h="1px"
                                bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                w="100%"
                            />

                            <CorreccionTutor
                                correccion={entregable?.correccion}
                            />
                        </>
                    }

                    <Box h="1px"
                        bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                        w="100%"
                    />

                    <EntregarFile
                        realizarEntrega={realizarEntrega}
                        endPrev={endPrev}
                        tipo={TipoEntregaEnum.REENTREGA}
                        leccion={leccion}
                        loading={loading}
                    />
                </>
            }
        </Flex>
    )
}