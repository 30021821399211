import { Flex, Skeleton, Text, useColorMode } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useData } from "../../../../shared/hooks/useData"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useState } from "react"
import { Table } from "../../../../shared/components/Table/Table"
import { TypeCardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"
import { LiquidacionesColumns } from "./Columns/LiquidacionesColumns"
import { LiquidacionesInt } from "../../../../interfaces/LiquidacionesInt"
import { Filter } from "../../components/Filter"

export default function Facturacion() {
  const { colorMode } = useColorMode();
  const [query, setQuery] = useState<{
    fechaPagoInicio?: string;
    fechaPagoFin?: string;
  }>({})
  const { data: liquidaciones, loading } = useData({
    endpoint: EndpointTypes.LIQUIDACIONES,
    query: {
      ...query
    }
  })

  const total = (() => {
    let total = 0;

    if (!liquidaciones || liquidaciones?.meta?.total === 0) return total

    for (const liquidacion of liquidaciones?.data) {
      if (liquidacion?.meta?.importeTotal) {
        total += liquidacion?.meta?.importeTotal
      }
    }

    return total
  })();

  return (
    <Topbar
      title="Facturación"
      searchBar
    >
      < Flex
        px="50px"
        py="20px"
        w="100%"
        direction="column"
        gap="20px"
      >
        {loading ?
          <Flex
            gap="20px"
            alignItems="center"
          >
            <Skeleton
              w="310px"
              h="66px"
              rounded="16px"
            />

            <Skeleton
              w="310px"
              h="66px"
              rounded="16px"
            />
          </Flex>
          :
          <Flex
            gap="20px"
            alignItems="center"
          >
            <Flex
              p="20px"
              border="1px solid"
              bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
              borderColor={colorMode === "dark" ? "border_variant" : "#E6E6Ea"}
              rounded="16px"
              alignItems="center"
              justifyContent="space-between"
              w="310px"
            >
              <Text
                color="font"
                fontSize="14px"
                fontWeight="500"
              >
                Importe pediente total
              </Text>

              <Text
                color="font"
                fontSize="16px"
                fontWeight="600"
              >
                {total}€
              </Text>
            </Flex>

            <Flex
              p="20px"
              border="1px solid"
              bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
              borderColor={colorMode === "dark" ? "border_variant" : "#E6E6Ea"}
              rounded="16px"
              alignItems="center"
              justifyContent="space-between"
              w="310px"
            >
              <Text
                color="font"
                fontSize="14px"
                fontWeight="500"
              >
                Facturas pendientes
              </Text>

              <Text
                color="font"
                fontSize="16px"
                fontWeight="600"
              >
                {liquidaciones?.data?.filter((l: LiquidacionesInt) => !l?.liquidada)?.length || 0}
              </Text>
            </Flex>
          </Flex>
        }

        <Filter
          query={query}
          setQuery={setQuery}
        />

        <Table
          columns={LiquidacionesColumns()}
          data={liquidaciones?.data}
          loading={loading}
          total={liquidaciones?.meta?.total}
          typeSinInformacion={TypeCardSinInformacion.LIQUIDACIONES}
        />
      </Flex>
    </Topbar >
  )
}
