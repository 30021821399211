import { Flex, Text, useToast } from '@chakra-ui/react';
import { useData } from '../../../../../shared/hooks/useData';
import { EndpointTypes } from '../../../../../shared/utils/types/EndpointTypes';
import { CardSinInformacion, TypeCardSinInformacion } from '../../../../../shared/components/Cards/CardSinInformacion';
import { CursosInt } from '../../../../../interfaces/CursosInt';
import CardCursoTutor from '../../../components/Aprendizaje/CardCursoTutor';
import { SkeletonAprendizajeCursos } from '../../../components/skeletons/SkeletonAprendizajeCursos';
import { useEffect } from 'react';
import { handleUnauthorized } from '../../../../../shared/utils/functions/handleErrors';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../../shared/context/user.context';

export const AprendizajeCursos
    = () => {
        const { data: cursos, loading, error, errorsList, status } = useData({
            endpoint: EndpointTypes.CURSOS,
            query: {
                limit: 100
            }
        });
        const { logout, user } = useAuthContext();
        const navigate = useNavigate()
        const toast = useToast()

        useEffect(() => {
            if (error) {
                handleUnauthorized({
                    errors: errorsList,
                    status: status,
                    logout: logout,
                    navigate: navigate,
                    toast: toast
                })
            }
        }, [error])

        return (
            (loading) ? <SkeletonAprendizajeCursos />
                :
                <Flex
                    direction="column"
                    px="10px"
                    w="full"
                    gap="40px"
                >
                    {cursos?.meta?.total !== 0 ?
                        <Flex
                            gap="40px"
                        >
                            <Flex
                                direction="column"
                                gap="40px"
                                w="50%"
                            >
                                <Flex
                                    direction="column"
                                    w="full"
                                    gap="20px"
                                >
                                    <Flex
                                        w="full"
                                    >
                                        <Flex
                                            w="100%"
                                            direction="column"
                                            gap="20px"
                                        >

                                            {cursos?.data?.map((curso: CursosInt, index: number) => (
                                                <CardCursoTutor
                                                    key={index}
                                                    curso={curso}
                                                />
                                            ))

                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        :
                        <CardSinInformacion type={TypeCardSinInformacion.CURSOS} />
                    }
                </Flex>
        );
    }