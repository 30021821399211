import { Button, Flex, Icon, Skeleton, Text, useColorMode, useDisclosure, useToast } from "@chakra-ui/react"
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { CardCuestionario } from "./utils/CardCuestionario";
import { CardDiploma } from "./utils/CardDiploma";
import { fmtSnds } from "../../../shared/utils/functions/formatters";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getDiploma } from "../../../shared/middlewares/users.middleware";
import { customToast, toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { BiDownload } from "react-icons/bi";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import InfoDiplomaModal from "../../../shared/components/Modals/InfoDiplomaModal";
import { useDataId } from "../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardFinalizacion = ({
    matricula
}: Props) => {
    const navigate = useNavigate()
    const toast = useToast();
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(false);
    const { data: curso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: matricula?.grupo?.curso?.slug,
        ignoreRequest: !matricula
    })
    const { colorMode } = useColorMode()

    const checkExamen = (): boolean => {
        const hasExamen = !!curso?.examen && !!curso?.examenId

        if (!hasExamen) return true;
        else {
            if (!matricula?.evaluaciones || matricula?.evaluaciones?.length === 0) return false;
            else return matricula?.evaluaciones?.find((evaluacion: any) => evaluacion?.aprobado) ? true : false;
        }
    }

    const checkRequisitos = (tiempoAcceso: boolean): boolean => {
        if (matricula?.grupo?.curso?.tutorizado === false) {
            if (matricula?.progresoLecciones! >= 75) return true;
            else return false;
        }

        if (tiempoAcceso === false) {
            if (
                matricula?.puntuacionMedia! >= 75 &&
                matricula?.progresoLecciones! >= 75 &&
                matricula?.progresoEntregables! >= 75
            ) return true;
            else
                return false;
        }

        if (
            matricula?.puntuacionMedia! >= 75 &&
            matricula?.progresoLecciones! >= 75 &&
            matricula?.progresoEntregables! >= 75 &&
            parseInt(fmtSnds(matricula?.tiempoAcceso)) >= ((matricula?.grupo?.curso?.duracion || 0) * 75) / 100
        ) return true;

        return false;
    }

    const handleDiploma = (matriculaId: string | undefined) => {
        if (!matriculaId) return;
        setLoading(true)

        getDiploma(matriculaId)
            .then((response: any) => {
                toast.closeAll()
                isOpen && onClose()

                customToast({
                    toast: toast,
                    status: StatusEnumTypes.SUCCESS,
                    title:
                        <Flex
                            alignItems="center"
                            gap="15px"
                        >
                            <Text
                                fontWeight="500"
                            >
                                Diploma generado correctamente
                            </Text>

                            <Button
                                h="fit-content"
                                w="fit-content"
                                p="5px 15px"
                                fontSize="13px"
                                fontWeight="500px"
                                border="1px solid"
                                borderColor="purewhite"
                                color="purewhite"
                                leftIcon={<Icon as={BiDownload} boxSize="20px" />}
                                bg="inherit"
                                rounded="5px"
                                _hover={{ bg: "inherit" }}
                                _active={{ transform: "scale(0.9)" }}
                                onClick={() => {
                                    window.open(response?.data?.fichero, '_blank')
                                    toast.closeAll()
                                }}
                            >
                                Descargar
                            </Button>
                        </Flex>
                })
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))

                toast.closeAll()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleCuestionario = () => {
        if (!matricula) return;

        if (!matricula?.formularioCompletado) return navigate(`/cuestionario?curso=${matricula?.grupo?.curso?.slug}&grupo=${matricula?.grupo?.slug}`)

        return handleDiploma(matricula?.id);
    }


    return (
        matricula ?
            <Flex
                w="50%"
                p="20px"
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "border"}
                rounded="16px"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                direction="column"
                gap="24px"
                h="255px"
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    color="font"
                >
                    Diploma
                </Text>

                <Flex
                    direction="column"
                    gap="10px"
                    justifyContent="center"
                    h="100%"
                >
                    {matricula?.grupo?.fundae &&
                        <CardCuestionario
                            handleCuestionario={handleCuestionario}
                            isDisabled={
                                !checkExamen() ? true : !checkRequisitos(matricula?.grupo?.permitirDescargaDiploma ? false : true)
                            }
                            tooltip="Debes cumplir con todos los requisitos para poder realizar el cuestionario."
                            isCompleted={matricula?.formularioCompletado}
                        />
                    }

                    <CardDiploma
                        loading={loading}
                        handleDiploma={() =>
                            (!matricula?.diplomaDescargado || !matricula?.fechaDescargaDiploma)
                                ? onOpen()
                                : handleDiploma(matricula?.id)
                        }
                        isDisabled={
                            !checkExamen() ? true :
                                matricula?.grupo?.fundae
                                    ? !matricula?.formularioCompletado
                                    : !checkRequisitos(false)
                        }
                        tooltip={
                            !checkExamen() 
                                ? "Debes realizar el examen para poder descargar el diploma."
                                : matricula?.grupo?.fundae
                                    ? "Debes completar el cuestionario para poder descargar el diploma."
                                    : "Debes cumplir con todos los requisitos para poder descargar el diploma."
                        }
                        isFundae={!matricula?.grupo?.fundae ? false : true}
                    />
                </Flex>

                <InfoDiplomaModal
                    isOpen={isOpen}
                    onClose={onClose}
                    handleDiploma={handleDiploma}
                    matricula={matricula}
                    loading={loading}
                />
            </Flex>
            :

            <Skeleton
                w="50%"
                h="255px"
                rounded="16px"
            />
    )
}