import { Button, Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import startImg from "../../../../../assets/img/examenStart.png"
import { useNavigate } from "react-router-dom";
import { BiLaptop, BiPlay, BiTime, BiWindowClose } from "react-icons/bi";

interface Props {
    onStartExamen: () => void;
}

export const StartQuiz = ({
    onStartExamen,
}: Props) => {
    const { colorMode } = useColorMode()

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            p="90px"
            gap="80px"
            mx="40px"
            mt="40px"
            h="full"
        >
            <Flex
                flex="1"
                justifyContent="center"
                direction="column"
                gap="50px"
            >
                <Flex
                    direction="column"
                    gap="18px"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        Antes de empezar....
                    </Text>

                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="150%"
                    >
                        Las certificaciones son una manera que tenemos de validar vuestro nivel de conocimientos de la manera más objetiva posible. Para obtener las certificaciones tendrás que seguir las normas y recomendaciones que te facilitamos en esta página.
                    </Text>
                </Flex>

                <Flex
                    gap="20px"
                >
                    <Button
                        color="#FFF"
                        fontSize="18px"
                        fontWeight="700"
                        bg="main"
                        px="24px"
                        py="14px"
                        rounded="14px"
                        cursor="pointer"
                        onClick={onStartExamen}
                        rightIcon={
                            <Icon
                                boxSize="24px"
                                as={BiPlay}
                            />
                        }
                    >
                        Empezar ahora
                    </Button>
                </Flex>
            </Flex>

            <Flex
                flex="1"
            >
                <Flex
                    justifyContent="center"
                    direction="column"
                    gap="40px"
                    rounded="20px"
                    p="40px"
                    border="1px solid"
                    borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                    bg={colorMode === "dark" ? "bg_dark_light" : "#F8F8F9"}
                >
                    <Flex
                        gap="17px"
                    >
                        <Icon
                            boxSize="20px"
                            color="fail"
                            as={BiTime}
                        />

                        <Flex
                            direction="column"
                            gap="10px"
                        >
                            <Text
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="22px"
                                letterSpacing="-0.408px"
                                color="font"
                            >
                                Límite de x minutos
                            </Text>

                            <Text
                                fontSize="15px"
                                fontWeight="400"
                                lineHeight="150%"
                                color="font"
                            >
                                Dispones de x minutos para realizar la certificación. El temporizador empezará a contar en cuanto pulses el botón “Empezar ahora”.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        gap="17px"
                    >
                        <Icon
                            boxSize="20px"
                            color="fail"
                            as={BiWindowClose}
                        />

                        <Flex
                            direction="column"
                            gap="10px"
                        >
                            <Text
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="22px"
                                letterSpacing="-0.408px"
                                color="font"
                            >
                                No abandones la página
                            </Text>

                            <Text
                                fontSize="15px"
                                fontWeight="400"
                                lineHeight="150%"
                                color="font"
                            >
                                Mantén el ratón en la página en todo momento y no cambies de página. Si cambias o abandonas la página se te descontarán 5 minutos del tiempo disponible que tienes para realizar la certificación.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex
                        gap="17px"
                    >
                        <Icon
                            boxSize="20px"
                            color="main"
                            as={BiLaptop}
                        />

                        <Flex
                            direction="column"
                            gap="10px"
                        >
                            <Text
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="22px"
                                letterSpacing="-0.408px"
                                color="font"
                            >
                                Utiliza un PC o portátil
                            </Text>

                            <Text
                                fontSize="15px"
                                fontWeight="400"
                                lineHeight="150%"
                                color="font"
                            >
                                Recomendamos realizar las certificaciones desde un ordenador para evitar posibles fallos de visualización de las mismas.
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}