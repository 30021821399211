import { Box, Flex, Button, Text, Image, useColorMode } from '@chakra-ui/react'
import logoProcademy from '../../../assets/img/LogoProcademyBlack.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { useAuthContext } from '../../../shared/context/user.context'
import { UserRolEnum } from '../../../shared/utils/types/UserRolEnum'
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import tenantInstance from '../../../shared/services/tenantInstance.service'
import { iconoTenant } from '../../../shared/middlewares/tenant.middleware'
import { checkHashExists, setPassword } from '../../../shared/middlewares/token.middleware'
import { useTenantInfo } from '../../../shared/hooks/useTenantInfo'
import { isRoleAllowed } from '../../../shared/utils/functions/validateRol'
import { toastNotify } from '../../../shared/utils/functions/toastNotify'
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes'

export const FormRecovery = () => {
    const { hash } = useParams();
    const { login } = useAuthContext();
    const navigate = useNavigate();
    const toast = useToast();
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });
    const { colorMode } = useColorMode();
    const [loading, setLoading] = useState<boolean>(true)
    const [loadingWidth, setLoadingWidth] = useState("0%")
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    useEffect(() => {
        if (!hash) return

        checkHashExists(hash)
            .catch(() => {
                navigate("/recovery/solicitud")
                toastNotify(toast, StatusEnumTypes.ERROR, "El email ha caducado vuelva a pedir un cambio de contraseña")
            })
    }, [hash])

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!loading) return;

        let width = 0;
        const interval = setInterval(() => {
            width += 1;
            setLoadingWidth(`${width}%`);

            if (width >= 90) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, [loading]);

    const initialValues = {
        password: null,
        passwordRepeat: null,
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .matches(passwordRules, { message: "La contraseña debe tener mas de 8 carácteres, y contener mayúsculas, minúsculas y números" })
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        passwordRepeat: Yup.string()
            .oneOf([Yup.ref("password")], 'Las contraseñas deben coincidir.')
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
    });

    const submitForm = async (values: any) => {
        const { tenant } = await tenantInstance();

        if (!hash) return

        const newPassword = {
            hashString: hash,
            password: values.password,
            passwordRepeat: values.passwordRepeat
        }

        setPassword(newPassword)
            .then((response: AxiosResponse) => {
                const token = response?.data?.token?.token;

                if (isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR, UserRolEnum.ALUMNO], response?.data?.data?.role?.nombre)) {
                    login(token, response?.data?.data, navigate)
                } else if (isRoleAllowed([UserRolEnum.SUPERVISOR], response?.data?.data?.role?.nombre)) {
                    if (tenant === "imagina") return window.location.href = `${import.meta.env.VITE_BASE_ADMIN}/login?loginByCampus=true&token=${token}`;
                    else
                        return window.location.href = `https://${tenant}${import.meta.env.VITE_BASE_ADMIN}/login?loginByCampus=true&token=${token}`;
                } else {
                    toastNotify(toast, StatusEnumTypes.WARNING, "No tiene autorizacion para ingresar!")
                }
            })
            .catch(() => {
                navigate("/recovery/solicitud")
                toastNotify(toast, StatusEnumTypes.ERROR, "El plazo ha caducado vuelva a pedir un cambio de contraseña")
            })
    };

    return (
        <Flex
            mt="auto"
            direction="column"
            alignItems="center"
            gap="30px"
        >
            <Box
                pos={"fixed"}
                top="0"
                bg="main"
                h="3px"
                w={loadingWidth}
            />

            {loading ?
                <Flex
                    h="65px"
                    rounded="6px"
                />
                :
                (
                    (icono && nombre) ?
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Image
                                src={icono}
                                alt='img/logo'
                                objectFit="contain"
                                w="65px"
                                h="65px"
                            />

                            <Text
                                textTransform="capitalize"
                                fontFamily="Poppins"
                                fontSize="22px"
                                color="font"
                                fontWeight="800"
                            >
                                {nombre}
                            </Text>
                        </Flex>
                        :
                        <Image
                            src={logoProcademy}
                            alt='img/logo'
                            objectFit="contain"
                            w="200px"
                            p="0 3%"
                        />
                )
            }

            <Flex
                direction="column"
                alignItems="center"
            >
                <Text
                    fontFamily="Poppins"
                    fontWeight="800"
                    fontSize="44px"
                    color="font"
                >
                    Crea tu contraseña
                </Text>

                <Text
                    mt="20px"
                    fontWeight="400"
                    lineHeight="normal"
                    fontSize="14px"
                    color="font"
                >
                    Crea aquí la contraseña con la que accaderás al campus
                </Text>
            </Flex>

            {!loading &&
                <Formik
                    onSubmit={(values) => submitForm(values)}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                                style={{ minWidth: "420px" }}
                            >
                                <Flex
                                    direction="column"
                                    alignItems="center"
                                    gap="5px"
                                >
                                    <FormInput
                                        type="password"
                                        name='password'
                                        placeholder='Introduzca la contraseña'
                                        ui={{
                                            styleInput: {
                                                border: colorMode === "dark" ? "1px solid #576776" : "1px solid #E6E6EA",
                                                bg: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                color: colorMode === "dark" ? "purewhite" : "font"
                                            },
                                        }}
                                    />

                                    <FormInput
                                        type="password"
                                        name='passwordRepeat'
                                        placeholder='Confirme la contraseña'
                                        ui={{
                                            styleInput: {
                                                border: colorMode === "dark" ? "1px solid #576776" : "1px solid #E6E6EA",
                                                bg: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                                color: colorMode === "dark" ? "purewhite" : "font"
                                            },
                                        }}
                                    />

                                    <Button
                                        mt="10px"
                                        type='submit'
                                        background='main'
                                        color="#FFFFFF"
                                        fontSize="18px"
                                        fontWeight="800"
                                        rounded="12px"
                                        w="100%"
                                        h="fit-content"
                                        py="11px"
                                        px="25px"
                                    >
                                        Confirmar
                                    </Button>
                                </Flex>
                            </FormikForm>
                        );
                    }}
                </Formik>
            }
        </Flex>
    )
}